import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppConfig } from '@config/app.config';
import { Observable, of, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PullRequestEnvService {
    private readonly httpClient: HttpClient;
    public branchesSignal: Signal<string[]>;
    public currentBranchSignal = signal('main');

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler); // skipping interceptor
        this.branchesSignal = toSignal(this.getBranches().pipe(shareReplay(1)), {
            initialValue: []
        });
    }

    public setBranch(branchName: string): void {
        this.currentBranchSignal.set(branchName);
    }

    public redirectToBranch(branchName: string): void {
        const url = new URL(window.location.href);
        if (branchName !== 'main') {
            url.searchParams.set('branch', branchName);
        } else {
            url.searchParams.delete('branch');
        }
        window.history.pushState({}, '', url);
        window.location.reload();
    }

    private getBranches(): Observable<string[]> {
        if (AppConfig.config.STAGE !== 'sandbox') {
            return of([]);
        }

        return this.httpClient.get<string[]>(
            'https://sandbox-analytics.bannerflow.com/deployment-slots.json'
        );
    }
}

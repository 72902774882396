import { Serializable } from '@shared/interfaces/serializable.interface';
import { Palette } from './palette.model';
import { IImageLogo } from '@analytics/models/image-logo.model';

export class Brand implements Serializable<Brand> {
    public id: string;
    public name: string;
    public logo: string;
    public slug: string;
    public accountSlug: string;
    public impressionsLastWeek: number;
    public targetUrl: string;
    public amountOfUsers: number;
    public amountOfBanners: number;
    public amountOfBannerSets: number;
    public amountOfCreatives: number;
    public amountOfCreativeSets: number;
    public lastModified: Date;
    public bannerImageWeightLimit: number;
    public bannerWeightLimit: number;
    public imageDefaultNameConvention: string;
    public landingPageDefaultNameConvention: string;
    public studioImageDefaultNameConvention: string;
    public studioVideoDefaultNameConvention: string;
    public culture: string; // Mongo ID for default culture
    public palettes: Palette[];
    public videoDefaultNameConvention: string;
    public totalAmountOfCreativeAndBanners: number;
    public totalAmountOfCreativeAndBannerSets: number;

    /**
     * state is 0 or 1,
     * 0 means the brand is activated.
     * 1 means the brand is not activated, it is marked as deleted
     */
    public state?: number;

    public deserialize(json: any): Brand {
        if (!json) {
            return null;
        }
        this.id = json.id;
        this.name = json.name;
        this.logo = json.logo;
        this.slug = json.slug;
        this.accountSlug = json.accountSlug;
        this.impressionsLastWeek = json.impressionsLastWeek;
        this.targetUrl = json.targetUrl;
        this.amountOfUsers = json.amountOfUsers;
        this.amountOfBanners = json.amountOfBanners;
        this.amountOfBannerSets = json.amountOfBannerSets;
        this.amountOfCreatives = json.amountOfCreatives;
        this.amountOfCreativeSets = json.amountOfCreativeSets;
        this.totalAmountOfCreativeAndBanners = this.amountOfBanners + this.amountOfCreatives;
        this.totalAmountOfCreativeAndBannerSets =
            this.amountOfBannerSets + this.amountOfCreativeSets;
        this.lastModified = json.lastModified;
        this.culture = json.culture;
        this.bannerImageWeightLimit = json.bannerImageWeightLimit / 1024;
        this.bannerWeightLimit = json.bannerWeightLimit / 1024;
        this.imageDefaultNameConvention = json.imageDefaultNameConvention;
        this.landingPageDefaultNameConvention = json.landingPageDefaultNameConvention;
        this.studioImageDefaultNameConvention = json.studioImageDefaultNameConvention;
        this.studioVideoDefaultNameConvention = json.studioVideoDefaultNameConvention;
        this.videoDefaultNameConvention = json.videoDefaultNameConvention;
        if (json.state) {
            this.state = json.state;
        }

        this.palettes = json.palettes
            ? json.palettes.map((palette: any) => new Palette().deserialize(palette))
            : [];

        return this;
    }

    public serialize(): any {
        return {
            name: this.name,
            targetUrl: this.targetUrl,
            bannerImageWeightLimit: +this.bannerImageWeightLimit * 1024,
            bannerWeightLimit: +this.bannerWeightLimit * 1024,
            imageDefaultNameConvention: this.imageDefaultNameConvention
                ? this.imageDefaultNameConvention
                : '',
            landingPageDefaultNameConvention: this.landingPageDefaultNameConvention
                ? this.landingPageDefaultNameConvention
                : '',
            videoDefaultNameConvention: this.videoDefaultNameConvention
                ? this.videoDefaultNameConvention
                : '',
            studioImageDefaultNameConvention: this.studioImageDefaultNameConvention
                ? this.studioImageDefaultNameConvention
                : '',
            studioVideoDefaultNameConvention: this.studioVideoDefaultNameConvention
                ? this.studioVideoDefaultNameConvention
                : ''
        };
    }
}

export class NewBrandDto {
    public name: string;
    public targetUrl: string;
    public defaultLanguageId: string;
    public palettes: Palette[];
    public logo: IImageLogo;
    public userIdsForBrand: string[];
}

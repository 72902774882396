import { Injectable } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { Brand } from '@shared/models/brand.model';
import { ApiService } from '../api.service';
import { config } from '@config/environment.dev';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BrandService {
    public activeBrands: Brand[] = [];
    public allBrands: Brand[] = [];
    private simpleBrands: Brand[] = [];

    constructor(
        private readonly apiService: ApiService,
        private readonly sessionService: SessionService,
        private readonly httpClient: HttpClient
    ) {}

    public async getBrands(
        clearCache?: boolean,
        isSimpleBrand?: boolean,
        includeDeleted?: boolean
    ): Promise<Brand[]> {
        if (!clearCache && this.allBrands.length) {
            if (includeDeleted) {
                return this.allBrands;
            }

            return this.activeBrands;
        }

        if (isSimpleBrand && this.simpleBrands.length) {
            return this.simpleBrands;
        }

        const brands: Brand[] = await this.apiCall(includeDeleted);

        if (isSimpleBrand) {
            // cache simple brands
            this.simpleBrands = brands;
        }

        return brands;
    }

    private async apiCall(includeDeleted?: boolean): Promise<Brand[]> {
        let allBrands: Brand[] = [];

        try {
            allBrands = await this.getSimpleBrands();

            let brands = allBrands.map((brand) => {
                return new Brand().deserialize(brand);
            });
            brands = this.sortBrandsByNameAsc(brands);
            this.allBrands = brands;
            this.activeBrands = this.getActivated(brands);
            if (!includeDeleted) {
                return this.activeBrands;
            }
            return brands;
        } catch (error) {
            console.error('An error occurred during the API call:', error);
            throw error;
        }
    }

    private async getSimpleBrands(): Promise<Brand[]> {
        const brandSimpleUrl = `${config.ACCOUNT_ACCESS_URL}/api/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/users/current/brands/simple`;
        try {
            const brands: Brand[] = await firstValueFrom(
                this.httpClient.get<Brand[]>(brandSimpleUrl)
            );

            return brands;
        } catch (error) {
            throw error;
        }
    }

    /**
     * sends a request to BE to toggle brand deletion.
     * If the brand is active, that will result in a brand soft deletion,
     * If the brand is deleted, it will undo that deletion
     * @param brandId Id of the brand to be toggled
     */
    public async activateDeactivatebrand(brandId: string): Promise<number> {
        const url = `/api/v2/a/${this.sessionService.user.account.slug}/brand/${brandId}/state`;

        await this.apiService.put(url, null, { anonymous: true, errorNotification: true });

        let newState: number;
        this.allBrands.forEach((brand) => {
            if (brand.id === brandId) {
                newState = brand.state ? 0 : 1;
                brand.state = newState;
            }
        });
        this.activeBrands = this.getActivated(this.allBrands);

        return newState;
    }

    private getActivated(brands: Brand[]): Brand[] {
        const activatedBrands: Brand[] = [...brands.filter((brand) => brand.state !== 1)];

        return activatedBrands;
    }

    private sortBrandsByNameAsc(brands: Brand[]): Brand[] {
        return brands.sort((brand1, brand2) => {
            if (brand1.name.toLowerCase() > brand2.name.toLowerCase()) {
                return 1;
            }

            if (brand1.name.toLowerCase() < brand2.name.toLowerCase()) {
                return -1;
            }

            return 0;
        });
    }
}

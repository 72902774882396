import { Component, ElementRef, ViewChild } from '@angular/core';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { FormsModule } from '@angular/forms';

// Used in dialogService.openComponent() functions no html references
@Component({
    selector: 'approveDialogComponent',
    templateUrl: './approveDialog.component.html',
    styleUrls: ['./approveDialog.component.scss'],
    standalone: true,
    imports: [UIModule, FormsModule]
})
export class ApproveDialogComponent {
    @ViewChild('dialog') public dialog: UIDialogDirective;
    @ViewChild('emailInput') public emailInput: ElementRef;

    public callback: Function;
    public email: string;

    public ngAfterViewInit(): void {
        if (this.emailInput && this.emailInput.nativeElement) {
            this.emailInput.nativeElement.focus();
        }
    }

    public initiate(): Promise<any> {
        const promise: Promise<any> = new Promise<any>((resolve) => {
            this.callback = resolve;
        });

        return promise;
    }

    public continue(): void {
        if (!this.email) {
            return;
        }

        const dialogResponse: any = {};
        dialogResponse.data = this.email;

        this.callback(dialogResponse);
    }

    public cancel(): void {
        const dialogResponse: any = {};
        dialogResponse.cancel = true;

        this.callback(dialogResponse);
    }
}

//  libs
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { PreviewService } from '@core/services/api/bannerflow/preview.service';
import { SessionService } from '@core/services/internal/session.service';
import { UtilsService } from '@core/services/internal/utils.service';
import { CommentsService } from '@shared/components/comment/comments.service';
import { Banner } from '@shared/models/banner/banner.model';
import {
    BANNER_BUTTON_APPROVE,
    BANNER_BUTTON_BANNERTAG,
    BANNER_BUTTON_COMMENT
} from '@shared/models/banner/bannerGroup.model';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { Size } from '@shared/models/banner/size.model';
import { Comment } from '@shared/models/comment.model';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { NgClass, NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { CommentsComponent } from '../comment/comments.component';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';

@Component({
    selector: 'bf-banner',
    templateUrl: 'banner.component.html',
    styleUrls: ['banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(document:click)': 'handleDocumentClick($event)'
    },
    standalone: true,
    imports: [NgClass, NgIf, UIModule, NgStyle, CommentsComponent, AsyncPipe, EllipsisPipe]
})
export class BannerComponent implements OnInit, OnDestroy {
    @Input() public banner: Banner;
    @Input() public bannerSet: BannerSet;
    @Input() public maxWidth = 9999;
    @Input() public maxHeight = 9999;
    @Input() public actions = false;
    @Input() public size: Size;
    @Input() public buttons: string[] = [];
    @Input() public header = true;
    @Input() public autoRender = false;
    @Input() public renderInIframe = false;
    @Input() public responsive = false;
    @Input() public renderWithScript = false;
    @Input() public usePadding = true;
    @Input() public bannerFeedPreview = false;
    @Input() public originalTranslationId: string;
    @Input() public displayZigZag = true;

    @Output() public onPreviewClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() public bannerApiLoaded: EventEmitter<any> = new EventEmitter<any>();

    public bannerSize: Size;
    public bannerScale = 1;
    public loading: boolean;
    public approving: boolean;
    public commentsOpen: boolean;
    public activeButtons: any = {};
    public previewing: boolean;

    public showActions = true;
    public showBannerTagButton: boolean;
    public showApprove: boolean;
    public showComments: boolean;

    public render = false;
    public safeBannerHtml$: Observable<SafeHtml>;
    public safeBannerImage: SafeResourceUrl;

    private bannerScript: any;
    private playbackIsPaused = false;
    private loadInterval: any;
    private checkBannerApiInterval: any = null;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly commentsService: CommentsService,
        private readonly sessionService: SessionService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private previewService: PreviewService
    ) {}

    public ngOnInit(): void {
        this.loading = true;
        this.safeBannerHtml$ = this.getSafeBannerHtml();
        this.safeBannerImage = this.getSafeBannerImage();

        if (!this.renderWithScript) {
            this.renderWithScript = !this.banner.bannerFormat.adType.isRegularAd;
            this.render = true;
        }

        // Handle max size
        if (this.maxHeight && this.maxWidth) {
            const scale: number =
                1 /
                Math.max(
                    this.banner.bannerFormat.sizeFormat.size.width / (this.maxWidth - 50),
                    this.banner.bannerFormat.sizeFormat.size.height / this.maxHeight
                );

            this.bannerScale = scale < 1 ? scale : 1;
        }

        // If got size constraints
        if (this.size) {
            this.setSize(this.size);
        } else {
            // this.setSize(this.size);
            this.bannerSize = this.banner.bannerFormat.sizeFormat.size;
        }

        // Prepare button settings
        for (const button of this.buttons) {
            this.activeButtons[button] = true;
        }

        this.showActions = this.buttons.length !== 0;

        // Stop loading banner if render with script is activated
        if (this.renderWithScript) {
            // If advanced format and auto render is set to true, render the AF directly
            if (this.autoRender) {
                this.previewing = true;
                this.render = true;

                this.changeDetectorRef.markForCheck();
                this.changeDetectorRef.detectChanges();
                this.renderScript();
            } else {
                this.loading = false;
            }
        }

        // unused?
        this.showBannerTagButton =
            (!this.sessionService.hasFeature(SessionService.FEATURES.BANNERSETDONEMARK) ||
                (this.sessionService.hasFeature(SessionService.FEATURES.BANNERSETDONEMARK) &&
                    this.bannerSet?.isDone)) &&
            this.activeButtons[BANNER_BUTTON_BANNERTAG] &&
            !UtilsService.isMobile();

        this.showApprove =
            this.sessionService.hasFeature(SessionService.FEATURES.APPROVE) &&
            this.activeButtons[BANNER_BUTTON_APPROVE];

        this.showComments = this.activeButtons[BANNER_BUTTON_COMMENT];
    }

    public ngOnDestroy(): void {
        this.onPreviewClick.unsubscribe();
        if (this.loadInterval) {
            clearInterval(this.loadInterval);
        }
    }

    public iframeLoaded(): void {
        if (!this.loading) {
            return;
        }

        const banners: any =
            this.elementRef.nativeElement.querySelectorAll('.banner__iframe iframe');

        // Sometimes the banner iframes is not available directly
        if (!banners.length) {
            this.loadInterval = setInterval(() => {
                const banners: any =
                    this.elementRef.nativeElement.querySelectorAll('.banner__iframe iframe');

                if (banners.length) {
                    clearInterval(this.loadInterval);
                    this.loadInterval = null;
                    this.addIframeListeners(banners);
                    this.loading = false;
                    this.changeDetectorRef.markForCheck();
                }
            }, 200);

            return;
        }

        this.addIframeListeners(banners);
        this.loading = false;
        this.changeDetectorRef.markForCheck();

        if (!this.renderWithScript) {
            const banner: any = banners[0];
            this.checkBannerApiInterval = setInterval(() => {
                if (
                    banner.contentWindow?.BF?.LastParsedFeedData
                ) {
                    clearInterval(this.checkBannerApiInterval);
                    this.checkBannerApiInterval = null;
                    this.bannerApiLoaded.emit(banner);
                }
            }, 100);
        }
    }

    private addIframeListeners(bannerIframes: HTMLIFrameElement[]): void {
        const self = this;

        if (!bannerIframes.length) {
            return;
        }

        // Edge doesn't support forEach on nodelist
        bannerIframes.forEach((bannerIframe: HTMLIFrameElement) => {
            bannerIframe.removeEventListener('load', checkIfLoaded); // Is this really needed?
            bannerIframe.addEventListener('load', checkIfLoaded);

            function checkIfLoaded(): void {
                // Make sure iframe have content
                if (bannerIframe.contentWindow) {
                    const iframeDoc: Document =
                        bannerIframe.contentDocument || bannerIframe.contentWindow.document;

                    if (iframeDoc.readyState === 'complete') {
                        if (self.playbackIsPaused) {
                            bannerIframe.contentWindow.postMessage({ type: 'pause' }, window.location.origin);
                        } else {
                            bannerIframe.contentWindow.postMessage({ type: 'play' }, window.location.origin);
                        }
                        bannerIframe.removeEventListener('load', checkIfLoaded);

                        return;
                    }
                    setTimeout(checkIfLoaded, 200);
                }
            }
            checkIfLoaded();
        });
    }

    public showButton(button: string): boolean {
        return this.buttons.indexOf(button) !== -1;
    }

    public approve(): Promise<Comment> {
        this.approving = true;
        const approve = !this.banner.approved?.approved;

        const promise: Promise<Comment> = this.commentsService.toggleApprove(
            this.bannerSet,
            approve,
            this.banner
        );

        promise
            .then(() => {
                this.approving = false;
                this.changeDetectorRef.markForCheck();
            })
            .catch(() => {
                setTimeout(() => {
                    this.approving = false;
                    this.changeDetectorRef.markForCheck();
                }, 2500);

                return Promise.reject(null);
            });

        return promise;
    }

    public setApproved(): void {
        if (!this.banner.approved) {
            this.banner.approved = new Comment();
        }

        this.banner.approved.approved = true;

        this.changeDetectorRef.markForCheck();
    }

    public preview(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.onPreviewClick.emit();
    }

    public copyTag(): Promise<boolean> {
        UtilsService.copyToClipboard(this.banner.placementScript);

        return new Promise<boolean>((resolve) => {
            resolve(true);
        });
    }

    public openComments(): void {
        this.commentsOpen = true;
    }

    public setSize(size: Size): void {
        if (!this.banner) {
            return;
        }

        this.bannerSize = size;
        this.bannerScale = this.bannerSize.width / this.banner.bannerFormat.sizeFormat.size.width;
        this.changeDetectorRef.markForCheck();
    }

    private readonly bannerScriptLoaded = (): void => {
        this.iframeLoaded();
    };

    private renderScript(): void {
        if (!this.render) {
            return;
        }

        this.loading = true;
        this.changeDetectorRef.markForCheck();

        const iframe: HTMLIFrameElement = document.createElement('iframe');
        this.bannerScript = document.createElement('script');
        const iframePreview: any = this.elementRef.nativeElement.querySelector('.banner__iframe');

        const bannerContainer: HTMLDivElement = document.createElement('div');
        bannerContainer.style.width = bannerContainer.style.height = '100%';
        bannerContainer.id = this.banner.id;

        iframePreview.appendChild(bannerContainer);

        this.bannerScript.type = 'text/javascript';
        this.bannerScript.className = 'bannerflow_preview_script';
        this.bannerScript.addEventListener('load', this.bannerScriptLoaded);

        this.bannerScript.src =
            this.banner.previewScript +
            (this.responsive ? `?responsive=on&container=${this.banner.id}` : '');

        if (this.renderInIframe) {
            iframe.src = '/Empty';
            iframe.scrolling = 'no';
            iframe.addEventListener('load', () => {
                iframe.contentDocument.body.appendChild(this.bannerScript);
                iframe.contentDocument.body.id = this.banner.id;
                iframe.contentDocument.body.style.margin = '0';
                iframe.contentDocument.body.style.padding = '0';
            });
            iframePreview.appendChild(iframe);
        } else {
            iframePreview.appendChild(this.bannerScript);
        }
    }

    public showBanner(): void {
        if (this.render) {
            return;
        }

        this.render = true;
        this.changeDetectorRef.markForCheck();
    }

    public hideBanner(): void {
        if (!this.render) {
            return;
        }

        this.render = false;
        this.changeDetectorRef.markForCheck();
    }

    public isInView(): boolean {
        return UtilsService.isElementInView(
            this.elementRef.nativeElement.querySelector('.banner__iframe')
        );
    }

    public restartBanner(): void {
        if (!this.renderWithScript) {
            this.render = false;
            this.changeDetectorRef.markForCheck();

            setTimeout(() => {
                this.render = true;
                this.changeDetectorRef.markForCheck();
            }, 1);
        } else {
            if (this.autoRender) {
                this.previewing = true;
                this.changeDetectorRef.markForCheck();
                this.bannerScript.removeEventListener('load', this.bannerScriptLoaded);
                this.elementRef.nativeElement.querySelector('.banner__iframe').innerHTML = '';
                this.renderScript();
            } else {
                this.bannerScript.removeEventListener('load', this.bannerScriptLoaded);
                this.elementRef.nativeElement.querySelector('.banner__iframe').innerHTML = '';
                this.previewing = false;
                this.changeDetectorRef.markForCheck();
            }
        }

        this.playbackIsPaused = false;
    }

    public togglePlayback(): void {
        const banners: any =
            this.elementRef.nativeElement.querySelectorAll('.banner__iframe iframe');

        if (!this.playbackIsPaused) {
            this.playbackIsPaused = true;
            banners.forEach((banner: any) => {
                banner.contentWindow.postMessage({ type: 'pause' }, window.location.origin);
            });
        } else {
            this.playbackIsPaused = false;
            banners.forEach((banner: any) => {
                banner.contentWindow.postMessage({ type: 'play' }, window.location.origin);
            });
        }
    }

    /*
     * Function to close the location picker if you click outside of it.
     */
    public handleDocumentClick(event: any): void {
        if (this.commentsOpen) {
            let clickedComponent: any = event.target;

            let inside = false;
            // Make sure it's only closed if the user actually clicks outside of the component.
            do {
                let insideClass = false;

                if (clickedComponent.classList) {
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < clickedComponent.classList.length; i++) {
                        if (clickedComponent.classList[i] === 'ui-popover-panel') {
                            insideClass = true;
                            break;
                        }
                    }
                }

                if (clickedComponent === this.elementRef.nativeElement || insideClass) {
                    inside = true;
                    break;
                } else {
                    inside = false;
                }

                clickedComponent = clickedComponent.parentNode;
            } while (clickedComponent);

            if (!inside) {
                this.commentsOpen = false;
            }
        }
    }

    private getSafeBannerHtml(): Observable<SafeHtml> {
        // Hack to get around browsers cross origin frame block
        return this.previewService.getBannerHtml(this.banner).pipe(
            map((bannerHtml) => this.sanitizer.bypassSecurityTrustHtml(bannerHtml)),
            distinctUntilChanged()
        );
    }

    private getSafeBannerImage(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.banner.previewImage);
    }
}

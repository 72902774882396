import { Component, Input } from '@angular/core';
import { BrandService } from '@core/services/api/bannerflow/brand.service';
import { Brand } from '@shared/models/brand.model';
import { ObjectDropDownComponent } from '../objectDropDown.component';
import { NgClass, NgIf } from '@angular/common';
import { BFDropDownComponent } from '../../dropDown/bfDropDown.component';
import { BrandPickerComponent } from './brand.picker.component';

@Component({
    selector: 'brandDropDown',
    templateUrl: 'brand.dropdown.component.html',
    styleUrls: ['../objectDropDown.component.scss'],
    standalone: true,
    imports: [NgClass, BFDropDownComponent, NgIf, BrandPickerComponent]
})
export class BrandDropDownComponent extends ObjectDropDownComponent {
    @Input() public placeholder = 'No brand selected';

    constructor(private brandService: BrandService) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        if (this.selected != null) {
            const brands: Brand[] = await this.brandService.getBrands();

            this.selected = this.filterSelectedByBrands(this.selected, brands);

            this.setLabel();
        }
    }

    private filterSelectedByBrands(selected: string[], brands: Brand[]): string[] {
        return selected.filter((selectedId: string) =>
            brands.find((brand) => brand.id === selectedId)
        );
    }
}

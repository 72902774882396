import { ICampaignFolder } from '../campaign/api/campaign.interface';
import { IFolderableItem } from '../folder/folderable-item.interface';
import { ISharedCampaignFolder, ISharedCampaignListItem } from './shared-campaign-list';

/**
 * Represents Shared Campaign List with folders
 */
export class SharedCampaignUIListDataNode<
    T extends ISharedCampaignFolder | ISharedCampaignListItem =
        | ISharedCampaignFolder
        | ISharedCampaignListItem
> implements IFolderableItem<T>
{
    public name: string;
    public data: T;
    public modified: Date;
    public isFolder?: boolean;
    public children?: SharedCampaignUIListDataNode<T>[];
    public totalCount?: number;

    public static isCampaignFolder(
        data: ISharedCampaignFolder | ISharedCampaignListItem
    ): data is ICampaignFolder {
        return 'folderId' in data && data.folderId instanceof Object;
    }

    public static createFromCampaigns(
        campaigns: ISharedCampaignListItem[]
    ): SharedCampaignUIListDataNode<ISharedCampaignListItem>[] {
        return campaigns.map((campaign) =>
            SharedCampaignUIListDataNode.createFromCampaign(campaign)
        );
    }

    public static createFromFolders(
        subFolders: ISharedCampaignFolder[]
    ): SharedCampaignUIListDataNode[] {
        return Array.isArray(subFolders)
            ? subFolders.map((folder) => SharedCampaignUIListDataNode.createFromFolder(folder))
            : [];
    }

    public static createFromFolder(folder: ISharedCampaignFolder): SharedCampaignUIListDataNode {
        return {
            name: folder.name,
            modified: folder.modified ? new Date(folder.modified) : new Date(folder.created),
            isFolder: true,
            data: folder,
            totalCount: -1,
            children: this.createFromFolders(folder.subfoldersList)
        };
    }

    public static createFromCampaign(
        campaign: ISharedCampaignListItem
    ): SharedCampaignUIListDataNode<ISharedCampaignListItem> {
        return {
            name: campaign.name,
            modified: campaign.modified ? new Date(campaign.modified) : new Date(campaign.created),
            isFolder: false,
            data: campaign
        };
    }
}

import { Injectable } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '@config/app.config';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InfoService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly authService: AuthService,
        private readonly http: HttpClient
    ) {}

    protected async setupHeaders(): Promise<HttpHeaders> {
        const token: string = await lastValueFrom(this.authService.getAccessTokenSilently());
        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        return headers;
    }

    public async initBFInfoGlobalVariable(): Promise<void> {
        if (!this.sessionService.user) {
            throw new Error('User is not available');
        }
        const accountSlug = this.sessionService.user.account?.slug;
        const brandSlug = this.sessionService.user.brand?.slug;
        if (!accountSlug || !brandSlug) {
            throw new Error('🚧 Account or Brand slug is not available 🚧');
        }
        const info: any = await lastValueFrom(
            this.http.get(`${AppConfig.config.ACCOUNT_ACCESS_URL}/account/${accountSlug}/tracking`)
        );

        if (!info) {
            return;
        }
        info.user.accounts = info.accounts;
        info.user.currentAccount = info.accounts.find(
            (account: any) => account?.account?.slug === accountSlug
        );
        if (!info.user.currentAccount) {
            throw new Error('🚧 Current account not found 🚧');
        }
        info.user.currentBrand = info.user.currentAccount?.brands?.find(
            (brand: any) => brand?.slug === brandSlug
        );
        if (!info.user.currentBrand) {
            throw new Error('🚧 Current brand not found 🚧');
        }
        info.user.isEmployee = info.user.employee === 3;
        info.user.title = this.sessionService.user.title;

        // https://github.com/nordicfactory/BannerFlow/blob/972fac1a36755d2c35818c3e2455b2cf9cdc1e37/BannerFlow/BannerFlow/Content/js/controllers/MainController.js#L35C5-L44
        for (const plan of info.user.currentAccount.account.plans) {
            if (plan.planCode.substr(0, 3) === 'bf-') {
                info.user.currentAccount.plan = plan;
                break;
            }
        }
        (window as any).BF_INFO = Object.freeze(info);
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { SharedCampaignListService } from '@core/services/api/shared-campaign-list/shared-campaign-list.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { ISharedFolderId } from '@shared/models/shared-campaign-list/shared-campaign-list';
import { SharedCampaignUIListDataNode } from '@shared/models/shared-campaign-list/shared-campaign-list-ui';
import { DatatableComponent, SelectionType, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'campaignPicker',
    templateUrl: 'campaign.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, NgxDatatableModule, UIModule]
})
export class CampaignPickerComponent implements OnChanges, OnInit {
    @Input() public folder: any;
    @Input() public brandSlug: string;
    @Input() public multi = false;
    @Input() public placeholder = 'None';
    @Input() public allowNoneSelection: boolean;

    @Input() public selected: string[] = [];
    @Input() public selectedObjects: any | any[] = [];

    @Output() protected selectedChange: EventEmitter<any> = new EventEmitter<any>(); // Emit pure ID:s

    @ViewChild('datatable') protected datatable: DatatableComponent;

    public type: ObjectType;
    public tableRowsOriginal: any[];
    public tableRows: any[];
    public loading = true;
    public breadcrumbs: ISharedFolderId[] = [];
    public currentFolderId: ISharedFolderId;
    public searchQuery: string;
    public allCampaigns: SharedCampaignUIListDataNode[] = [];

    // Pagination
    public currentPage = 1;
    public currentCount = 0;
    public hasMore = true;

    public selectedIds: string[];

    private folders: SharedCampaignUIListDataNode[];
    public SelectionType: typeof SelectionType = SelectionType;

    constructor(private readonly campaignListService: SharedCampaignListService) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.brandSlug) {
            this.loading = true;
            await Promise.all([this.loadAllCampaigns(), this.loadFolders()]);
            await this.loadItems();
            this.loading = false;
        }
    }

    async ngOnInit(): Promise<void> {
        await Promise.all([this.loadAllCampaigns(), this.loadFolders()]);
        await this.loadItems();
    }

    private async loadAllCampaigns(): Promise<void> {
        this.allCampaigns = SharedCampaignUIListDataNode.createFromCampaigns(
            await this.campaignListService.getAllCampaigns(this.brandSlug)
        );
    }

    private async loadFolders(): Promise<void> {
        this.folders = await this.campaignListService.getCampaignFolders(this.brandSlug);
    }

    private async loadItems(): Promise<void> {
        this.currentFolderId = this.currentFolderId || this.folder;

        let rows: SharedCampaignUIListDataNode[];
        let campaigns: SharedCampaignUIListDataNode[];

        const folder: SharedCampaignUIListDataNode = this.currentFolderId
            ? this.findFolderFromTree(this.currentFolderId, this.folders)
            : null;

        if (folder && SharedCampaignUIListDataNode.isCampaignFolder(folder.data)) {
            if (!this.breadcrumbs.includes(folder.data.folderId)) {
                this.breadcrumbs.push(folder.data.folderId);
            }
            campaigns = await this.getCampaignsByFolderId(folder.data.folderId);
            rows = [...folder.children, ...campaigns];
        } else {
            this.breadcrumbs = [];
            campaigns = await this.getCampaignsByFolderId();
            rows = [...this.folders, ...campaigns];
        }

        this.tableRows = rows;

        if (this.multi) {
            this.selectedObjects = this.tableRows.filter((row: any) => {
                const item: ExObjectItem<any> = this.getRowValue(row);

                const selected: any = this.selected
                    ? this.selected.find((id: string) => id === item.data.id)
                    : undefined;

                return !!selected;
            });
        } else {
            if (this.allowNoneSelection) {
                this.tableRows = [{ id: null, name: this.placeholder }, ...this.tableRows];
            }
        }

        this.tableRowsOriginal = this.tableRows;

        setTimeout(() => {
            this.datatable.recalculate();
        });

        this.loading = false;
    }

    public onTableClick(tableEvent: any): void {
        if (tableEvent.type === 'checkbox') {
            return;
        } else if (tableEvent.type === 'click') {
            const row: any = tableEvent.row;
            const item: ExObjectItem<any> = this.getRowValue(row);

            if (!item.isFolder) {
                if (this.multi) {
                    const selectedId: string = item.data.id;
                    this.toggleItem(selectedId);
                    this.selectedChange.emit(this.selected);
                } else {
                    this.selectedChange.emit(item.data);
                }
            } else {
                this.currentFolderId = item.data.folderId;
                this.loadItems();
            }
        }
    }

    private toggleItem(selectedId: string): void {
        if (!this.selected) {
            this.selected = [];
        }

        const alreadySelectedIndex: number = this.selected.findIndex(
            (selected: string) => selected === selectedId
        );

        if (alreadySelectedIndex === -1) {
            this.selected.push(selectedId);
        } else {
            this.selected.splice(alreadySelectedIndex, 1);
        }
    }

    protected getPreviousFolderId(): ISharedFolderId {
        if (this.breadcrumbs && this.breadcrumbs.length > 1) {
            this.breadcrumbs.splice(-1, 2);

            return this.breadcrumbs[this.breadcrumbs.length - 1];
        } else {
            return null;
        }
    }

    protected getRowValue(row: any): ExObjectItem<any> {
        if (!row.data) {
            const item: ExObjectItem<any> = new ExObjectItem<any>();
            item.data = row;

            return item;
        } else {
            return row;
        }
    }

    public goUp(): void {
        const folderToGo: ISharedFolderId = this.getPreviousFolderId();

        this.currentFolderId = folderToGo;
        this.folder = null;

        this.loadItems();
    }

    public search(): void {
        this.tableRows = this.allCampaigns.filter((row: any) => {
            const rowString: string = JSON.stringify(row).toLowerCase();
            if (this.searchQuery === '') {
                this.loadItems();
            }
            return rowString.indexOf(this.searchQuery.toLowerCase()) !== -1;
        });
    }

    public canSelect = (row: any): boolean => {
        return !row.isFolder;
    };

    public clear(): void {
        this.selected = [];
        this.selectedObjects = [];

        this.selectedChange.emit(this.selected);
    }

    private findFolderFromTree(
        folderId: ISharedFolderId,
        folderTree: SharedCampaignUIListDataNode[]
    ): SharedCampaignUIListDataNode {
        const foundFolder = folderTree.find(
            (item) =>
                SharedCampaignUIListDataNode.isCampaignFolder(item.data) &&
                item.data.folderId.self === folderId.self
        );

        if (foundFolder) {
            return foundFolder;
        } else {
            for (const childFolder of folderTree) {
                const foundFolder = this.findFolderFromTree(folderId, childFolder.children);

                if (foundFolder) {
                    return foundFolder;
                }
            }
        }
    }

    private async getCampaignsByFolderId(
        folderId?: ISharedFolderId
    ): Promise<SharedCampaignUIListDataNode[]> {
        return await this.campaignListService.getCampaignListByFolderId(folderId, this.brandSlug);
    }
}

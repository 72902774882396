import { Injectable } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { ExObjectItem, ExObjectQuery, ExObjectResult } from '@shared/classes/ExObjectQuery';
import { Schedule } from '@shared/models/schedule.model';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class ScheduleService {
    constructor(
        private readonly apiService: ApiService,
        private readonly sessionService: SessionService
    ) {}

    public getSchedulesEx(query: ExObjectQuery): Promise<ExObjectResult<Schedule>> {
        const promise = new Promise<any>((resolve, reject) => {
            this.apiService
                .post(
                    `/api/v1/b/[accountSlug]/${query.brand || this.sessionService.user.brand.id}/meta/schedules/`,
                    query,
                    { anonymous: true }
                )
                .then((data: any) => {
                    if (data) {
                        const result: ExObjectResult<Schedule> = new ExObjectResult<Schedule>();

                        const items = data.data.content.map((item: any) => {
                            const bannerSet = new Schedule().deserialize(item);

                            const exItem: ExObjectItem<Schedule> = new ExObjectItem<Schedule>();
                            exItem.data = bannerSet;
                            exItem.isFolder = item.isFolder;

                            return exItem;
                        });

                        result.items = items;
                        result.breadcrumbs = data.data.meta.breadCrumbs || [];
                        result.hasMore = data.data.hasMore;

                        if (data.data.meta.id) {
                            result.breadcrumbs.unshift({ name: null, id: null });
                        }

                        resolve(result);
                    } else {
                        reject();
                    }
                });
        });
        return promise;
    }

    public getSchedule(slug: string = ''): Promise<Schedule> {
        const promise = new Promise<Schedule>((resolve) => {
            this.apiService
                .get(`/api/v1/b/[accountSlug]/[brandSlug]/campaignschedule?slug=${slug}`, {
                    anonymous: true
                })
                .then((data: any) => {
                    resolve(new Schedule().deserialize(data.schedule));
                });
        });

        return promise;
    }
}

import { IAppConfig } from './app.config.type';

export const config: IAppConfig = {
    BUILD_VERSION: '<VERSION>',
    STAGE: 'sandbox',
    APP_URL: 'https://sandbox-analytics.bannerflow.com',
    IDP_URL: 'https://sandbox-idp.bannerflow.com',
    B2_URL: 'https://sandbox-app.bannerflow.com',
    STUDIO_URL: 'https://sandbox-studio.bannerflow.com',
    CAMPAIGN_SERVICE_URL: 'https://sandbox-api.bannerflow.com/cs',
    PUBLISH_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ps',
    MIGRATION_SERVICE_URL: 'https://sandbox-migrationservice.bannerflow.com',
    ANALYTICS_BACKEND_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ab',
    ANALYTICS_REPORT_SCHEDULER_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ars',
    CAMPAIGN_MANAGER_URL: 'https://sandbox-cm.bannerflow.com',
    AD_SERVICE_URL: 'https://c.sandbox-bannerflow.net',
    AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING:
        'Endpoint=https://bf-shared-sandbox-ac.azconfig.io;Id=+d6H-l8-s0:ZDsMi4u4B3bL3Y9VAexD;Secret=kycnl5jmUghCUDu70c/LdKSinLqeEtkZE08EvTEsLK4=',
    SOCIAL_CAMPAIGN_SERVICE_URL: 'https://sandbox-scs.bannerflow.com',
    SOCIAL_CAMPAIGN_MANAGER_URL: 'https://sandbox-scm.bannerflow.com',
    SOCIAL_ACCOUNT_SERVICE_URL: 'https://sandbox-sas.bannerflow.net',
    SCHEDULE_MIGRATION_CLIENT_URL: 'https://sandbox-smc.bannerflow.com',
    GTM_ID: 'GTM-M2FZ5ZZ',
    INTERCOM_ID: undefined,
    LIST_SERVICE_URL: 'https://sandbox-api.bannerflow.com/list-service',
    ACCOUNT_ACCESS_URL: 'https://sandbox-api.bannerflow.com/account-access',
    BRAND_MANAGER_URL: 'https://sandbox-api.bannerflow.com/brand-manager',
    API_GATEWAY_URL: 'https://sandbox-api.bannerflow.com',
    CLIENT_ID: 'UWsnkgbv8vdzN3xKWmhaiWjCcVhE0x9H',
    LOGIN_URL: 'https://sandbox-login.bannerflow.com',
    FEATURE_FLAG: {
      enabled: true,
      url: 'https://bf-feature-flags.azurewebsites.net/api/frontend',
      clientKey: '*:development.26a12aa0d2ddd20c7e5519a984532c29a2c85c06aed6a279c28ee3c2'
    },
};

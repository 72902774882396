import { EventEmitter, ElementRef, OnInit, Directive, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Directive({
    selector: '[debounceInput]',
    standalone: true
})
export class DebounceInputDirective implements OnInit {
    @Input('debounceInputDelay') public delay = 500;
    @Output('debounceInputChange') public change: EventEmitter<any> = new EventEmitter();

    constructor(
        private readonly elementRef: ElementRef,
        private readonly model: NgModel
    ) {}

    public ngOnInit(): void {
        const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
            map(() => this.model.value),
            debounceTime(this.delay)
        );

        eventStream.subscribe((input) => this.change.emit(input));
    }
}

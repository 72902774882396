<div
    class="banner"
    [ngClass]="{
        'banner--actionsOpen': commentsOpen || approving,
        'banner--approved':
            banner.approved && banner.approved.approved && showActions && showApprove,
        'no-padding': !usePadding,
        'banner--responsive': responsive
    }"
>
    <!--FLAG & TRANSLATION NAME-->
    <div class="banner__header" *ngIf="header" uiTooltip="{{ banner.version.localization.name }}">
        <div class="banner__headerCountry">
            <ui-flag [culture]="banner.version.localization.culture.cultureCode"></ui-flag>
            {{ banner.version.localization.name | ellipsis: 20 : false }}
            <span class="originalText" *ngIf="banner.version.id === originalTranslationId"
                >(Original)</span
            >
        </div>
    </div>

    <!--APPROVED INDICATOR-->
    <div
        *ngIf="banner.approved && banner.approved.approved && showActions && showApprove"
        class="banner__approvedIcon"
    >
        <ui-icon icon="thumbs-up" class="thumbs-up"></ui-icon>
    </div>

    <div
        class="banner__inner"
        [style.width]="
            responsive
                ? '100%'
                : banner.approved && banner.approved.approved
                  ? bannerSize.width * bannerScale + 10 + 'px'
                  : ''
        "
    >
        <div
            class="banner__iframe"
            [ngClass]="{ 'no-padding': !usePadding }"
            [style.margin-top]="
                (banner.approved?.approved && showActions && showApprove) || responsive
                    ? '0'
                    : '13px'
            "
            [style.width]="responsive ? '100%' : bannerSize.width * bannerScale + 'px'"
            [style.height]="responsive ? 'auto' : bannerSize.height * bannerScale + 'px'"
            [style.maxWidth]="maxWidth + 'px'"
        >
            <ui-loader *ngIf="loading"></ui-loader>
            <div
                class="banner__iframeTooBig"
                *ngIf="
                    banner.bannerFormat.sizeFormat.size.width * bannerScale > maxWidth &&
                    displayZigZag
                "
                (click)="preview($event)"
                (touchend)="preview($event)"
                (keydown)="($event.key === 'Enter' || $event.key === ' ') && preview($event)"
            ></div>
            <div class="wrapper1">
                <div class="wrapper2">
                    <iframe
                        *ngIf="
                            !renderWithScript && render && safeBannerHtml$ | async as safeBannerHtml
                        "
                        (load)="iframeLoaded()"
                        [srcdoc]="safeBannerHtml"
                        [style.width]="banner.bannerFormat.sizeFormat.size.width + 'px'"
                        [style.height]="banner.bannerFormat.sizeFormat.size.height + 'px'"
                        [ngStyle]="{
                            transform: bannerScale != 1 ? 'scale(' + bannerScale + ')' : 'none'
                        }"
                        title="Banner preview"
                    ></iframe>
                </div>
            </div>
            <div
                class="banner__iframeImage"
                *ngIf="renderWithScript && !previewing"
                [style.backgroundImage]="'url(' + banner.previewImage + ')'"
                [style.width]="banner.bannerFormat.sizeFormat.size.width + 'px'"
                [style.height]="banner.bannerFormat.sizeFormat.size.height + 'px'"
                [ngStyle]="{ transform: bannerScale != 1 ? 'scale(' + bannerScale + ')' : 'none' }"
            ></div>
            <div
                *ngIf="renderWithScript && !previewing"
                class="banner__iframePreviewOverlay"
                (click)="preview($event)"
                (keydown)="($event.key === 'Enter' || $event.key === ' ') && preview($event)"
            >
                <div class="banner__previewButton">
                    <ui-icon icon="focus"></ui-icon>
                    <span>Preview</span>
                </div>
            </div>
        </div>
    </div>

    <!--BANNER MENU-->
    <div class="banner__actions" *ngIf="showActions">
        <!--COMMENT COUNT-->
        <div class="banner__actionsCommentsLabel" *ngIf="banner.comments.length && showComments">
            {{ banner.comments.length + (banner.comments.length > 1 ? " comments" : " comment") }}
        </div>

        <div
            class="banner__actionsButtons"
            [ngClass]="{ 'banner__actionsButtons--alignLeft': !showApprove }"
        >
            <div class="banner__fileSize">
                <!--HTML5 SIZE-->
                <span>
                    <span
                        uiTooltip="HTML5 banner is displayed directly after web page has loaded - polite loading. Its file-size does not have to comply to your ad networks' file-size limits."
                    >
                        <i aria-hidden="true" class="bf-icon bf-icon-type-html5 left-icon"></i>
                        <span *ngIf="banner.fileSize">{{ banner.fileSize | uiFormatBytes }}</span>
                        <span *ngIf="!banner.fileSize && banner.bannerFormat.fileSize">{{
                            banner.bannerFormat.fileSize | uiFormatBytes
                        }}</span>
                    </span>
                    <i
                        aria-hidden="true"
                        *ngIf="!banner.fileSize && !banner.bannerFormat.fileSize"
                        uiTooltip="Calculating file size"
                        class="bf-icon bf-icon-refresh bf-icon-spin bf-icon-fw"
                    ></i>
                    <i
                        aria-hidden="true"
                        *ngIf="
                            banner.fileSize > 307200 ||
                            (!banner.fileSize && banner.bannerFormat.fileSize > 307200)
                        "
                        uiTooltip="This file size is larger than recommended"
                        class="fa fa-exclamation-triangle"
                    ></i>
                </span>

                <!--IMAGE SIZE-->
                <span class="image-size">
                    <span
                        uiTooltip="Image banner is displayed while web page is loading. Make sure its file-size meets your ad networks' file-size limits."
                    >
                        <i aria-hidden="true" class="bf-icon bf-icon-type-image-o left-icon"></i>
                        <span *ngIf="banner.imageFileSize">{{
                            banner.imageFileSize | uiFormatBytes
                        }}</span>
                        <span *ngIf="!banner.imageFileSize && banner.bannerFormat.fileImageSize">{{
                            banner.bannerFormat.fileImageSize | uiFormatBytes
                        }}</span>
                    </span>
                    <i
                        aria-hidden="true"
                        *ngIf="!banner.imageFileSize && !banner.bannerFormat.fileImageSize"
                        uiTooltip="Calculating image size"
                        class="bf-icon bf-icon-refresh bf-icon-spin bf-icon-fw"
                    ></i>
                    <i
                        aria-hidden="true"
                        *ngIf="
                            banner.imageFileSize > 307200 ||
                            (!banner.imageFileSize && banner.bannerFormat.fileImageSize > 307200)
                        "
                        uiTooltip="This file size is larger than recommended"
                        class="fa fa-exclamation-triangle"
                    ></i>
                </span>
            </div>

            <div class="banner__subActionButtons">
                <!--APPROVE BUTTON-->
                <ui-button
                    class="banner__subActionApprove"
                    #approveButton
                    [disabled]="approving"
                    *ngIf="showApprove"
                    [ngClass]="{ unapproved: banner.approved && banner.approved.approved }"
                    [uiTooltip]="
                        banner.approved && banner.approved.approved
                            ? 'Unapprove banner'
                            : 'Approve banner'
                    "
                    (click)="approve()"
                    (keydown)="($event.key === 'Enter' || $event.key === ' ') && approve()"
                    icon="thumbs-up"
                    [text]="banner.approved && banner.approved.approved ? 'Unapprove' : 'Approve'"
                >
                </ui-button>

                <!--COMMENTS BUTTON-->
                <div class="banner__subActionComments" *ngIf="showComments">
                    <div class="banner__subActionCommentsAmount">
                        {{ banner.comments.length }}
                    </div>
                    <ui-button
                        uiTooltip="Comments"
                        (click)="popover.open(target); openComments()"
                        (keypress)="popover.open(target); openComments()"
                        ui-popover-target
                        #target="ui-popover-target"
                        [icon]="banner.comments.length ? 'comment' : 'comment-o'"
                    >
                    </ui-button>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>

    <ui-popover
        #popover="ui-popover"
        [config]="{ width: '500px', minWidth: '500px', maxHeight: '400px' }"
    >
        <ng-template ui-popover-template>
            <div class="comment__header">
                <i aria-hidden="true" class="bf-icon bf-icon-comment largeIcon"></i>
                <span class="headline">Comments ({{ banner.comments.length }})</span>
            </div>
            <comments [itemToComment]="banner" [parent]="bannerSet" [autoFocus]="true"> </comments>
        </ng-template>
    </ui-popover>
</div>

<div class="objectPicker">
    <div class="objectPicker__search">
        <input
            type="text"
            placeholder="Search campaign"
            spellcheck="false"
            debounceInput
            (debounceInputChange)="search()"
            [(ngModel)]="searchQuery"
        />
    </div>
    <div class="objectPicker__goUp" *ngIf="breadcrumbs && breadcrumbs.length" (click)="goUp()" (keydown)="($event.key === 'Enter' || $event.key === ' ') && goUp()">
        <i
            aria-hidden="true"
            class="folderIcon folderIcon--symbol bf-icon bf-icon-arrow-left-a"
        ></i>
        Go back
    </div>
    <div class="objectPicker__table">
        <ngx-datatable
            #datatable
            [class.loading-hidden]="loading"
            [rows]="tableRows"
            [columnMode]="'force'"
            [selected]="selectedObjects"
            [rowHeight]="40"
            [selectionType]="multi ? SelectionType.multiClick : SelectionType.single"
            [selectCheck]="canSelect"
            (activate)="onTableClick($event)"
            (selectedChange)="selectedObjects = $event"
        >
            <ngx-datatable-column
                *ngIf="multi"
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true"
            >
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="data.name">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        <i
                            aria-hidden="true"
                            class="itemIcon itemIcon--folder bf-icon bf-icon-folder-o"
                            *ngIf="row.isFolder"
                        ></i>
                        <ui-svg-icon class="itemIcon" *ngIf="!row.isFolder" icon="campaigns-l">
                        </ui-svg-icon>
                        {{ value }}
                        <i
                            aria-hidden="true"
                            class="itemIcon itemIcon--arrow bf-icon bf-icon-arrow-right-b"
                            *ngIf="row.isFolder"
                        ></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <ui-loader *ngIf="loading"></ui-loader>
    </div>
</div>

<div
    class="bfDropDown"
    (clickOutside)="toggleCollapsed(true)"
    [ngClass]="{ 'bfDropDown--collapsed': collapsed }"
>
    <div class="bfDropDown__title" (click)="toggleCollapsed()">
        <ng-content select="[bfDropDownTitle]"></ng-content>
        <div class="bfDropDown__caret">
            <i aria-hidden="true" class="bf-icon bf-icon-caret-down"></i>
        </div>
    </div>
    <div class="bfDropDown__content" *ngIf="!collapsed">
        <ng-content select="[bfDropDownContent]"></ng-content>
    </div>
</div>

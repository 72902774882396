import { AppConfig } from '@config/app.config';
import { initIntercom } from './js/intercom.js';
import { initGTM } from './js/gtm.js';

/**
 * Initializes scripts like gtm, intercom
 */
export class AppScripts {
    public static init(): void {
        initIntercom(AppConfig.config.INTERCOM_ID);
        if (AppConfig.config.GTM_ID && AppConfig.config.STAGE !== 'local') {
            initGTM(AppConfig.config.GTM_ID);
        }
    }
}

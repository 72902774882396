<div class="headerContent">
    <div class="headerContent__back" (click)="backClick()" (keydown)="($event.key === 'Enter' || $event.key === ' ') && backClick()"
    *ngIf="isBackNavigationVisible()">
        <ui-icon icon="arrow-left-a"></ui-icon>&nbsp;
        {{ getBackText() }}
    </div>
    <div class="headerContent__back" *ngIf="showBrandPicker">
        <headerContentBrandPicker></headerContentBrandPicker>
    </div>
    <div class="headerContent__actions" *ngIf="sessionService.isLoggedIn()">
        <environment-picker *ngIf="isSandbox"></environment-picker>
        <div class="headerContent__actionsIcons">
            <div id="interaction-announcement" class="headerContent__actionsIcon">
                <ui-svg-icon
                    class="announcement"
                    icon="announcement"
                    fill="var(--ui-color-primary)"
                    (click)="openIntercomNews()"
                    (keydown)="($event.key === 'Enter' || $event.key === ' ') && openIntercomNews()"
                ></ui-svg-icon>
            </div>
            <div
                id="interaction-help-center"
                #helpMenu
                class="headerContent__actionsIcon"
                [uiDropdownTarget]="helpMenuDropDownMenu"
                *ngIf="!isPublicShowcase"
            >
                <i aria-hidden="true" class="bf-icon bf-icon-help-tooltip">
                    <ui-dropdown
                        #helpMenuDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5
                            }
                        ]"
                    >
                        <ui-dropdown-item (click)="gotoSupport()" (keydown)="($event.key === 'Enter' || $event.key === ' ') && gotoSupport()"
                        >Knowledge base</ui-dropdown-item>
                        <ui-dropdown-item (click)="Intercom('showNewMessage')" (keydown)="($event.key === 'Enter' || $event.key === ' ') && Intercom('showNewMessage')"
                            >Chat with us</ui-dropdown-item
                        >
                    </ui-dropdown>
                </i>
            </div>

            <div
                id="interaction-settings-menu"
                class="headerContent__actionsIcon"
                #settingsMenu
                [uiDropdownTarget]="settingsDropDownMenu"
                *ngIf="hasSettingsFeature"
            >
                <i aria-hidden="true" class="bf-icon bf-icon-settings">
                    <ui-dropdown
                        #settingsDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5
                            }
                        ]"
                    >
                        <ui-dropdown-item
                            id="interaction-settings-manage-brands"
                            (click)="sessionService.goToA2SettingView('brands')" (keydown)="($event.key === 'Enter' || $event.key === ' ') && sessionService.goToA2SettingView('brands')"
                        >
                            Manage brands
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-users"
                            (click)="sessionService.goToA2SettingView('users')"
                            (keydown)="($event.key === 'Enter' || $event.key === ' ') && sessionService.goToA2SettingView('users')"
                        >
                            Manage users
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-publish-options"
                            *ngIf="hasStudioFeature"
                            (click)="sessionService.goToA2SettingView('publishoptions')"
                            (keydown)="($event.key === 'Enter' || $event.key === ' ') && sessionService.goToA2SettingView('publishoptions')"
                        >
                            Manage publish options
                        </ui-dropdown-item>
                        <ui-dropdown-item
                            id="interaction-settings-manage-social-integrations"
                            *ngIf="hasSocialCampaignManagerFeature"
                            (click)="sessionService.goToA2SettingView('socialintegrations')"
                            (keydown)="($event.key === 'Enter' || $event.key === ' ') && sessionService.goToA2SettingView('socialintegrations')"
                        >
                            Manage social integrations
                        </ui-dropdown-item>
                    </ui-dropdown>
                </i>
            </div>
        </div>

        <div
            #profileMenu
            id="interaction-user-menu"
            class="headerContent__actionsUser"
            [uiDropdownTarget]="profileDropDownMenu"
        >
            <div class="headerContent__actionsUserImage">
                <profileImage [showArrow]="true">
                    <ui-dropdown
                        #profileDropDownMenu
                        [positions]="[
                            {
                                originX: 'end',
                                originY: 'bottom',
                                overlayX: 'end',
                                overlayY: 'top',
                                offsetY: -5,
                                offsetX: 15
                            }
                        ]"
                    >
                        <ui-dropdown-item
                            id="interaction-user-menu-nav-to-profilesettings"
                            (click)="sessionService.navigateToProfileSettings()"
                            (keydown)="($event.key === 'Enter' || $event.key === ' ') && sessionService.navigateToProfileSettings()"
                            >Profile settings</ui-dropdown-item
                        >
                        <ui-dropdown-item id="interaction-user-menu-log-out" 
                            (click)="logOut()" 
                            (keydown)="($event.key === 'Enter' || $event.key === ' ') && logOut()"
                            >Log out</ui-dropdown-item
                        >
                    </ui-dropdown>
                </profileImage>
            </div>
        </div>
    </div>
</div>

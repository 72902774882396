import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { CampaignStatus } from '@shared/models/campaign/api/campaign-status.enum';
import { ICampaignListItem, IFolderId } from '@shared/models/campaign/api/campaign.interface';
import {
    CampaignType,
    ISharedCampaignListItem
} from '@shared/models/shared-campaign-list/shared-campaign-list';
import { SharedCampaignUIListDataNode } from '@shared/models/shared-campaign-list/shared-campaign-list-ui';
import { ISocialCampaign } from '@shared/models/social-campaign/social-campaign.model';
import { firstValueFrom, forkJoin } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SharedListWorkerService {
    constructor() {}

    public async mapCampaigns(
        getDisplayCampaignsPromises: () => Promise<ICampaignListItem[]>,
        getSocialCampaignsPromises: () => Promise<ISocialCampaign[]>,
        hasDisplayCampaignManager: boolean,
        hasSocialCampaignManager: boolean
    ): Promise<SharedCampaignUIListDataNode<ISharedCampaignListItem>[]> {
        const maybeDisplayCampaigns: Promise<ICampaignListItem[]> = hasDisplayCampaignManager
            ? getDisplayCampaignsPromises()
            : this.getEmptyDisplayCampaigns();

        const maybeSocialCampaigns: Promise<ISocialCampaign[]> = hasSocialCampaignManager
            ? getSocialCampaignsPromises()
            : this.getEmptySocialCampaigns();

        const [displayCampaignItems, socialCampaignItems] = await firstValueFrom(
            forkJoin([maybeDisplayCampaigns, maybeSocialCampaigns])
        );

        const sharedCampaignListItems: ISharedCampaignListItem[] = this.mapSocialDisplayCampaigns(
            displayCampaignItems,
            socialCampaignItems
        );

        return SharedCampaignUIListDataNode.createFromCampaigns(sharedCampaignListItems);
    }

    private getEmptyDisplayCampaigns(): Promise<ICampaignListItem[]> {
        return Promise.resolve([]);
    }

    private getEmptySocialCampaigns(): Promise<ISocialCampaign[]> {
        return Promise.resolve([]);
    }

    private mapSocialDisplayCampaigns(
        displayCampaigns: ICampaignListItem[],
        socialCampaigns: ISocialCampaign[]
    ): ISharedCampaignListItem[] {
        const displayCampaignListItems: ISharedCampaignListItem[] = displayCampaigns.map(
            (displayCampaign) => {
                return {
                    ...displayCampaign,
                    campaignType: CampaignType.Display
                };
            }
        );

        const socialCampaignListItems: ISharedCampaignListItem[] = socialCampaigns.map(
            (socialCampaign) => {
                return {
                    ...socialCampaign,
                    status: CampaignStatus.NotPublished,
                    campaignType: CampaignType.Social,
                    modified: socialCampaign?.modified.toString(),
                    created: socialCampaign?.created.toString()
                };
            }
        );

        return [...displayCampaignListItems, ...socialCampaignListItems];
    }

    public getNavigationUrlToCM(
        campaignType: CampaignType,
        accountSlug: string,
        brandSlug: string,
        campaignId: string,
        currentFolderId?: IFolderId,
        adListView?: boolean
    ): string {
        let url: string;
        let folderParams: string = '';
    
        if (currentFolderId) {
            folderParams = `?${this.getFolderParams(currentFolderId)}`;
        }
    
        switch (campaignType) {
            case CampaignType.Display:
                url = `${AppConfig.config.CAMPAIGN_MANAGER_URL}/${accountSlug}/${brandSlug}/campaign/${campaignId}/${adListView ? 'list' : 'editor'}`;
                return url + folderParams;
            case CampaignType.Social:
                return `${AppConfig.config.SOCIAL_CAMPAIGN_MANAGER_URL}/${accountSlug}/${brandSlug}/campaign/${campaignId}`;
            default:
                throw new Error(`Unsupported campaign type: ${campaignType}`);
        }
    }

    private getFolderParams(folderId: IFolderId): string {
        let result = '';
        if (folderId) {
            let folderParams = new HttpParams();
            for (const param in folderId) {
                if (param === 'parent' || param === 'root' || param === 'self') {
                    folderParams = folderParams.append(String(param), String(folderId[param]));
                }
            }
            result = folderParams.toString();
        }
        return result;
    }
}

<div class="objectDropDown" [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown (open)="onOpen()" (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
            <i
                aria-hidden="true"
                class="objectDropDown__clear bf-icon bf-icon-close-filled"
                (click)="clear($event)"
                (keydown)="($event.key === 'Enter' || $event.key === ' ') && clear($event)"
                *ngIf="multi && selected.length"
            ></i>
        </div>
        <div bfDropDownContent>
            <sizeFormatPicker
                #objectPicker
                [multi]="multi"
                [(selected)]="selected"
                (selectedChange)="change($event)"
                *ngIf="isOpen"
            ></sizeFormatPicker>
        </div>
    </bfDropDown>
</div>

import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { AppScripts } from '@plugins/scripts/app-scripts';

import { AppConfig } from '@config/app.config';
import { AppService } from './app/core/services/internal/app.service';
import { AuthService, provideAuth0 } from '@auth0/auth0-angular';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { APP_ROUTES } from './app/app.routes';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideFeatureFlags } from '@bannerflow/feature-flags'

// Enable production mode when running prod env
if (AppConfig.config.STAGE === 'production') {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, CoreModule, SharedModule, APP_ROUTES),
        AppService,
        AuthService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.IDP_URL,
                    AppConfig.config.LIST_SERVICE_URL,
                    AppConfig.config.STUDIO_URL
                ])
            })
        ),
        provideAuth0({
            domain: AppConfig.config.LOGIN_URL,
            clientId: AppConfig.config.CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid email bannerflow publishservice campaignservice studio analyticsbackend socialcampaignservice socialaccountservice profileservice listservice offline_access'
            },
            httpInterceptor: {
                allowedList: [
                    `${AppConfig.config.APP_URL}*`,
                    `${AppConfig.config.API_GATEWAY_URL}*`,
                    `${AppConfig.config.SOCIAL_ACCOUNT_SERVICE_URL}*`,
                    `${AppConfig.config.B2_URL}*`
                ]
            },
            useRefreshTokens: true
        }),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideFeatureFlags({
          enabled: AppConfig.config.FEATURE_FLAG.enabled,
          url: AppConfig.config.FEATURE_FLAG.url,
          clientKey: AppConfig.config.FEATURE_FLAG.clientKey,
          appName: 'Analytics'
      })
    ]
})
    .then(() => {
        AppScripts.init();
        // Set global
        // @ts-ignore
        window['AppConfig'] = AppConfig.config;
    })
    .catch((err) => {
        console.error(err);
    });

import { Component, ElementRef, Renderer2 } from '@angular/core';
import { SizeFormatService } from '@core/services/api/bannerflow/sizeformat.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { SizeFormat } from '@shared/models/banner/sizeFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'sizeFormatPicker',
    templateUrl: 'sizeformat.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, UIModule, NgxDatatableModule]
})
export class SizeFormatPickerComponent extends ObjectPickerComponent {
    constructor(
        private readonly sizeFormatService: SizeFormatService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.SizeFormat;

        this.tableRows = new Array<ExObjectItem<SizeFormat>>();
    }

    protected loadItems(): void {
        this.loading = true;

        this.sizeFormatService.getSizeFormats().then((sizeFormats: SizeFormat[]) => {
            this.tableRows = sizeFormats;

            super.loadItems();

            this.datatable.recalculate();

            this.loading = false;
        });
    }
}

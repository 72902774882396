import { Component, ElementRef, Renderer2 } from '@angular/core';
import { BrandService } from '@core/services/api/bannerflow/brand.service';
import { ExObjectItem } from '@shared/classes/ExObjectQuery';
import { ObjectType } from '@shared/enums/objectType.enum';
import { Brand } from '@shared/models/brand.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgIf, NgStyle } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'brandPicker',
    templateUrl: 'brand.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [NgxDatatableModule, NgIf, NgStyle, UIModule]
})
export class BrandPickerComponent extends ObjectPickerComponent {
    constructor(
        private readonly brandService: BrandService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.Brand;

        this.tableRows = new Array<ExObjectItem<Brand>>();
    }

    protected async loadItems(folder: string = ''): Promise<void> {
        this.loading = true;

        this.tableRows = await this.brandService.getBrands();

        super.loadItems();

        this.datatable.recalculate();

        this.loading = false;
    }
}

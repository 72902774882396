import { ExObjectItem } from '../../../classes/ExObjectQuery';
import { BannerSet } from '../../banner/bannerSet.model';
import { IFolderableItem } from '../../folder/folderable-item.interface';
import { CreativeSet } from '../../studio/creativeset.model';
import { Model } from '../lib/model';

/**
 * Represents B2 creative set
 */
export class ExCreativeSet extends Model<ExCreativeSet> implements IFolderableItem<CreativeSet> {
    public id: string;
    public name: string;
    public legacyId: string;
    public thumb: string;
    public created: Date;
    public modified: Date;
    public data: CreativeSet;
    public isFolder: boolean;
    public children?: ExCreativeSet[];
    public totalCount?: number;

    public deserialize(exBannerSet: ExObjectItem<BannerSet>): ExCreativeSet {
        if (!exBannerSet) {
            return this;
        }

        const exCreativeSet: ExCreativeSet = new ExCreativeSet();

        exCreativeSet.id = exBannerSet.data.creativesetId;
        exCreativeSet.name = exBannerSet.data.name;
        exCreativeSet.legacyId = exBannerSet.data.id;
        exCreativeSet.thumb = exBannerSet.data.thumb;
        exCreativeSet.isFolder = exBannerSet.isFolder;
        exCreativeSet.created = exBannerSet.isFolder
            ? undefined
            : new Date(exBannerSet.data.created);
        exCreativeSet.modified = exBannerSet.isFolder
            ? undefined
            : new Date(exBannerSet.data.modified);
        exCreativeSet.children = exBannerSet.isFolder ? [] : undefined;
        exCreativeSet.totalCount = exBannerSet.isFolder ? -1 : undefined;

        return exCreativeSet;
    }
}

<span class="control-label"
    >{{ label }}
    <ng-content></ng-content>
</span>
<ui-selectable-list [(selected)]="selected" (change)="updateSelected()">
    <ui-radio
        id="interaction-select-radio-list-item"
        *ngFor="let option of options"
        [label]="option.text"
        [value]="option.value"
        [disabled]="option.disabled"
    >
    </ui-radio>
</ui-selectable-list>

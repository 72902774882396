<div class="objectDropDown" [ngClass]="{ 'objectDropDown--active': selected && selected.length }">
    <bfDropDown (open)="onOpen()" (close)="onClose()">
        <div bfDropDownTitle>
            {{ label }}
            <i
                aria-hidden="true"
                class="objectDropDown__clear bf-icon bf-icon-close-filled"
                (click)="clear($event)"
                *ngIf="multi && selected.length"
            ></i>
        </div>
        <div bfDropDownContent>
            <adGroupPicker
                #objectPicker
                [multi]="multi"
                [(selected)]="selected"
                (selectedChange)="change($event)"
                [placeholder]="placeholder"
                [allowNoneSelection]="allowNoneSelection"
                *ngIf="isOpen"
                [campaignID]="campaignID"
            ></adGroupPicker>
        </div>
    </bfDropDown>
</div>

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppStoreModule } from './store/app-store.module';
import { AuthHttpInterceptor } from '@core/services/auth/auth.interceptor';

@NgModule({
    imports: [HttpClientModule, AppStoreModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    ]
})
export class CoreModule {}
